// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var contentContainer = Cx.cx([
      "flex",
      "flex-col",
      "gap-5",
      "[&_a]:text-teal",
      "[&_a]:hover:underline",
      "[&_p]:text-gray",
      "[&_h2]:text-black",
      "[&_h3]:text-black",
      "[&_h3]:font-bold",
      "[&_h2]:font-bold",
      "[&_h3]:text-3xl",
      "[&_h2]:text-4xl"
    ]);

var locationsContentContainer = Cx.cx([
      "grid",
      "lg:grid-cols-3",
      "lg:grid-flow-col",
      "grid-flow-row",
      "grid-cols-1",
      "gap-10",
      "lg:px-[130px]",
      "lg:py-[60px]",
      "p-7"
    ]);

var aboutContainer = Cx.cx([
      "lg:col-span-2",
      "flex",
      "flex-col"
    ]);

var topProvidersContainer = Cx.cx([
      "lg:col-span-1",
      "flex",
      "flex-col",
      "gap-5"
    ]);

var serviceCategory = Cx.cx([
      "text-gray",
      "font-medium"
    ]);

var localitiesTitle = Cx.cx([
      "text-black",
      "text-4xl"
    ]);

var localitiesSubtitle = Cx.cx([
      "text-gray",
      "text-2xl"
    ]);

var statsContainer = Cx.cx([
      "flex",
      "flex-wrap",
      "gap-[10px]",
      "justify-between",
      "mt-10",
      "mb-10"
    ]);

var stat = Cx.cx([
      "flex",
      "flex-col",
      "flex-grow",
      "min-h-[100px]",
      "bg-gray-50",
      "rounded",
      "px-[30px]",
      "py-5"
    ]);

var statNumber = Cx.cx([
      "font-semibold",
      "text-4xl"
    ]);

var statName = Cx.cx([
      "font-medium",
      "text-gray",
      "text-sm"
    ]);

export {
  contentContainer ,
  locationsContentContainer ,
  aboutContainer ,
  topProvidersContainer ,
  serviceCategory ,
  localitiesTitle ,
  localitiesSubtitle ,
  statsContainer ,
  stat ,
  statNumber ,
  statName ,
}
/* contentContainer Not a pure module */
